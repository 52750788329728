import * as React from "react"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <h1 style={{color: "white"}}>404: Not Found</h1>
    <p>You just hit a route that doesn t exist... the sadness.</p>
  </>
)

export default NotFoundPage
